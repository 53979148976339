<template>
  <div class="group/ccc-member-badge md:relative">
    <div
      class="larken relative grid h-full cursor-pointer grid-rows-[1fr_auto] overflow-hidden rounded-2xl bg-[#191919] bg-contain bg-center px-2 py-1.5 hover:bg-[#2a2517]"
    >
      <img
        class="absolute -left-[1px] top-[3px] w-5 rounded-full border-2 border-solid border-[#33FF9955] group-hover/ccc-member-badge:border-[#ECBA3355]"
        src="/tokens/maya.png"
        alt=""
      />
      <img
        class="absolute -right-[1px] -top-[1px] w-5 rounded-full border-2 border-solid border-[#33FF9955] group-hover/ccc-member-badge:border-[#ECBA3355]"
        src="/tokens/rune-square-small.png"
        alt=""
      />
      <span class="larken mt-auto text-center text-[10px] leading-tight text-[#ECBA33]">
        Volume<br />
        Competition
      </span>
    </div>
    <div
      class="md:top-100% absolute z-20 hidden pt-2.5 group-hover/ccc-member-badge:flex max-md:right-4 max-md:w-[calc(100%_-_32px)] md:right-1/2 md:translate-x-1/2"
    >
      <div
        class="grid overflow-hidden rounded-3xl bg-gradient-to-b from-[#271F08] to-black backdrop-blur-sm"
        style="
          box-shadow:
            0px 966px 270px 0px rgba(102, 75, 0, 0),
            0px 618px 247px 0px rgba(102, 75, 0, 0.02),
            0px 348px 209px 0px rgba(102, 75, 0, 0.08),
            0px 155px 155px 0px rgba(102, 75, 0, 0.14),
            0px 39px 85px 0px rgba(102, 75, 0, 0.16),
            0px 4px 50px 0px rgba(0, 0, 0, 0.25);
        "
      >
        <img class="col-start-1 row-start-1 w-full" src="/images/competition-bg.png" alt="" />
        <div class="relative col-start-1 row-start-1 flex w-[340px] flex-col gap-2.5 p-6">
          <div class="mt-auto grid w-full gap-2.5">
            <div
              class="m-auto mt-4 grid justify-items-center rounded-full bg-[#1F3C22] px-4 py-2.5 max-md:my-auto md:row-span-2 md:mb-auto"
            >
              <span class="larken text-2xl leading-none text-[#33FF99]">
                {{ secondsToString(timeRemaining) }}
              </span>
              <span class="mb-auto rounded-full text-center text-[10px] text-[#33FF99]/70">
                Sep 23th - {{ timeToString(endTimeInEst) }}
              </span>
            </div>
            <span class="larken mt-8 text-2xl leading-none text-[#ECBA33]"
              >Volume Competition!</span
            >
            <div class="text-sm leading-tight text-white/50">
              Swap any pairs on <span class="text-[#ECBA33]">MAYAChain</span> or
              <span class="text-[#ECBA33]">THORChain</span> and Top 10 traders (by volume) will
              share a prize pool of 0.333% of the total supply of our upcoming [redacted] token.
            </div>
            <NuxtLink
              to="/leaderboard"
              class="larken mt-8 flex items-center justify-center gap-2.5 rounded-full bg-[#ECBA33]/10 px-8 py-3.5 text-base leading-none text-[#ECBA33] hover:bg-[#ECBA33]/30"
            >
              Check Leaderboard
              <Icon class="rotate-[135deg]" icon="fluent:arrow-left-24-filled" />
            </NuxtLink>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Icon } from '@iconify/vue/dist/iconify.js'
import { computed, onMounted, ref } from 'vue'

const endTime = new Date('Thu Oct 31 2024 16:00:00 GMT-0500')
const endTimeInEst = computed(() => {
  endTime.setTime(endTime.getTime() + endTime.getTimezoneOffset() * 60 * 1000)
  const offset = -300 // Timezone offset for EST in minutes.
  const estDate = new Date(endTime.getTime() + offset * 60 * 1000)
  return estDate
})

const timeToString = (time: Date) => {
  const date = time.getDate()
  const month = time.toLocaleString('default', { month: 'short' })
  const hour = time.toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true })
  return `${date}th ${month}, ${hour} EST`
}

const timeRemaining = ref<number>(0)
const syncTimeRemaining = () => {
  timeRemaining.value = Math.floor((endTime.getTime() - new Date().getTime()) / 1000)
}
onMounted(() => {
  syncTimeRemaining()
  setInterval(() => {
    syncTimeRemaining()
  }, 1000)
})
const addZeroPrefix = (data: number) => `0${data}`.slice(-2)
const secondsToString = (seconds: number) => {
  const sec = seconds % 60
  const min = ((seconds - sec) / 60) % 60
  const hr = (((seconds - sec) / 60 - min) / 60) % 24
  const day = (((seconds - sec) / 60 - min) / 60 - hr) / 24
  return `${addZeroPrefix(day)}:${addZeroPrefix(hr)}:${addZeroPrefix(min)}:${addZeroPrefix(sec)}`
}
</script>
